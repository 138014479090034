import styled from 'styled-components';

// Components
import TopAreas from './components/top-areas/TopAreas';
import Landing from './components/landing-section';
import TopCompounds from './components/top-compounds/TopCompounds';
import RecomendedSection from './components/recommended-section/RecommendedSection';
import ServicesContainer from './components/services-cards/ServiceContainer';
import LaunchesBannerContainer from '../entity/launch/components/LaunchesBanner/LaunchesBannerContainer';
import { ConfigVariable } from '../../new-lib/types/common';
import CityScapeBannerPresentation from './components/cityscape';
import useQueryRedirect from '../../hooks/QueryRedirect';
import { useSelector } from 'react-redux';
import SahelBanner from '../sahel-mapbox/components/common/SahelBanner';
import Pathways from './components/pathways/Pathways';

const HomePageContainer = (props: any) => {
  const cityscapeFlag =
    props.configVariables.find((obj: ConfigVariable) => {
      return obj['config_key'] === 'cityscape_offers';
    })?.config_value || false;

  useQueryRedirect({ query_params: props?.query });

  const sahelMapFlag =
    props.configVariables.find((obj: ConfigVariable) => {
      return obj['config_key'] === 'sahel_map_web';
    })?.config_value || false;

  const isMobile = useSelector((state: any) => state.app.isMobile);
  return (
    <HomeContainer>
      <Landing />

      {props?.pathways?.length > 0 && (
        <HomeWrapper className="container-fluid">
          <Pathways pathways={props.pathways} />
        </HomeWrapper>
      )}

      {isMobile ? (
        <HomeWrapper className="container-fluid banners-container">
          {/* to Enable horizontal scroll on mobile */}
          <div className="banners-inner-container">
            {cityscapeFlag !== 'off' && (
              <CityScapeBannerPresentation config={cityscapeFlag} />
            )}
            {sahelMapFlag === 'true' && <SahelBanner />}
          </div>
        </HomeWrapper>
      ) : (
        <HomeWrapper className="container-fluid banners-container">
          {cityscapeFlag !== 'off' && (
            <CityScapeBannerPresentation config={cityscapeFlag} />
          )}
          {sahelMapFlag === 'true' && <SahelBanner />}
        </HomeWrapper>
      )}

      <div className="launches-home-banner container-fluid">
        <LaunchesBannerContainer launches={props.launches} />
      </div>

      <HomeWrapper className="container-fluid">
        <TopCompounds popularCompounds={props.popularCompounds} />
        {isMobile ? <ServicesContainer /> : <></>}
      </HomeWrapper>

      <HomeWrapper className="container-fluid">
        <TopAreas {...props} />
        {!isMobile ? <ServicesContainer /> : <></>}
        <RecomendedSection />
      </HomeWrapper>
    </HomeContainer>
  );
};

const HomeContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 64px;
  margin-bottom: 64px;

  @media screen and (max-width: ${props =>
      props.theme.breakPoints.tablet.max}) {
    gap: 32px;
    margin-bottom: 32px;
  }
`;

const HomeWrapper = styled.div<any>`
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 64px;

  &.banners-container {
    height: 180px;
    gap: 32px;
    display: flex;
    flex-direction: row;
    > div,
    a {
      width: 50%;
      height: 100%;
    }
  }
  @media screen and (max-width: ${props =>
      props.theme.breakPoints.mobile.max}) {
    gap: 32px;

    &.banners-container {
      height: 120px;
      overflow-x: scroll;
      ::-webkit-scrollbar {
        display: none;
      }

      > div,
      a {
        width: auto;
      }
      .banners-inner-container {
        height: 100%;
        width: max-content;
        display: flex;
        flex-direction: row;
        gap: 16px;
      }
    }
  }
`;

export default HomePageContainer;
